<template>
  <div class="my-cart">
    <Header />
    <div class="h-24"></div>
    <!-- <div class="breadcrumbs">
      <div class="container py-4 px-4 flex items-center uppercase is-size-6">
        <span class="flex items-center">
          <router-link :to="{ name: 'Home' }" class="has-text-grey"
            >Home</router-link
          >
          <span class="mx-2">/</span>
          <router-link :to="{ name: 'My Cart' }" class="has-text-grey">{{
            $route.name
          }}</router-link>
        </span>
      </div>
    </div> -->
    <b-taglist class="breadcrumbs container p-4">
      <b-tag size="is-large" rounded type="is-white">
        <router-link :to="{ name: 'Home' }" class="has-text-grey">
          <b-icon icon="home" type="is-grey"></b-icon>
        </router-link>
      </b-tag>
      <b-tag size="is-large" rounded type="is-light">
        <router-link :to="{ name: $route.name }" class="has-text-grey">
          <span>{{ $route.name }}</span>
        </router-link>
      </b-tag>
    </b-taglist>
    <div class="container p-4">
      <!-- <div class="is-size-4 has-text-grey mb-4" v-if="data.length">
        {{ $route.name }}
      </div> -->
      <table
        class="table is-fullwidth relative"
        v-if="localData && localData.length"
      >
        <b-loading :is-full-page="false" v-model="loading"></b-loading>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Code</th>
            <th>Description</th>
            <th>CBM</th>
            <th>Unit Price</th>
            <th>Quantity</th>
            <th>Total Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in localData" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>
              <img
                width="50"
                :src="item.image ? item.image.thumb : $default_image_thumb"
                loading="lazy"
              />
            </td>
            <td>
              {{ item.code }}
            </td>
            <td>
              <div>{{ item.name }}</div>
            </td>
            <td>{{ item.volume_packed }}</td>
            <td align="right">{{ item.pivot.price }} USD</td>
            <td>
              <div class="flex items-center">
                <div class="w-32 mr-4">
                  <b-numberinput
                    :ref="item.id + '-qty-input'"
                    v-model="item.pivot.quantity"
                    size="is-small"
                    type=""
                    min="1"
                    :editable="false"
                    controls-position="compact"
                    @input="
                      updateCartOnInput($event, `${item.id}-qty-input`, item.id)
                    "
                  />
                  <!-- @input="$refs[`${item.id}-qty-input`][0].value = $event" -->
                </div>
                <!-- <b-button
                  size="is-small"
                  @click.native="updateCart(item.id, `${item.id}-qty-input`)"
                  >Update</b-button
                > -->
              </div>
            </td>
            <td align="right">
              {{
                (
                  parseInt(item.pivot.quantity) * parseFloat(item.pivot.price)
                ).toFixed(2)
              }}
              USD
            </td>
            <td>
              <b-button
                type="is-small"
                expanded
                @click.native="removeFromCart(item.id)"
                >Remove</b-button
              >
            </td>
          </tr>
          <tr>
            <td colspan="9" align="left" class="has-text-grey">
              <!-- Upon placing the order, you will get a notification to your registered email containing the invoice hosted online via Stripe to fullfill or cancel the payment. -->
              <div class="px-4 text-sm">
                Upon submitting this request, we will review the contents of
                your order and will contact you through your registered email
                address. You may also give us special instructions, additional
                orders or submit a <i>request for cancellation</i> notice to our
                sales representatives. This order request is
                <strong>not final and will not proceed</strong> unless we
                recieve your final approval.

                <!-- This order request will be reviewed upon submission and will not proceed unless our sales representatives gets your final approval.
                You may also give us additional info or <i>request for a cancellation</i> through our sales representatives. -->
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" align="right" class="font-bold">Total CBM:</td>
            <td class="font-bold">{{ totalCbm }}</td>
            <td colspan="2" align="right" class="font-bold">Subtotal:</td>
            <td class="font-bold" align="right">{{ subTotal }} USD</td>
            <td>
              <b-button
                expanded
                type="is-gold"
                @click.native="sendOrder"
                :disabled="updating"
                >Send Order</b-button
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="is-empty flex items-center justify-center">
        <div class="has-text-grey">Your cart is empty.</div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Header from "@/components/client/Header";
export default {
  title: () => {
    return `My Cart - ${process.env.VUE_APP_TITLE}`;
  },
  data: () => ({
    loading: false,
    updating: false,
    convertedCart: null,
    localData: null
  }),
  components: {
    Header
  },
  computed: {
    totalCbm() {
      if (!this.localData.length) {
        return 0;
      } else {
        let res = 0;
        _.forEach(this.localData, item => {
          res += item.pivot.quantity * item.volume_packed;
        });
        return res.toFixed(2);
      }
    },
    subTotal() {
      if (!this.localData.length) {
        return 0;
      } else {
        let res = 0;
        _.forEach(this.localData, item => {
          res += item.pivot.quantity * item.pivot.price;
        });
        return res.toFixed(2);
      }
    }
  },
  methods: {
    updateCartOnInput(new_quantity, ref, product_id) {
      this.updating = true;
      let new_qty = new_quantity;
      let old_qty = this.$refs[ref][0].value;
      this.$store
        .dispatch("addToCart", {
          cart_id: this.$store.getters.activeUser.cart.id,
          product_id: product_id,
          product_quantity: new_qty,
          method: "assign",
          custom: this.$store.getters.activeUser.is_client || undefined
        })
        .then(() => {
          this.$refs[ref].value = new_quantity;
        })
        .catch(() => {
          this.$refs[ref].value = old_qty;
          this.$store.dispatch("displayMessage", {
            type: "is-warning",
            message: this.$store.getters.generalErrorMessage
          });
        })
        .finally(() => {
          this.updating = false;
        });
    },
    updateCart(product_id, product_quantity) {
      this.loading = true;
      let qty = this.$refs[product_quantity][0].value;
      this.$store
        .dispatch("addToCart", {
          cart_id: this.$store.getters.activeUser.cart.id,
          product_id: product_id,
          product_quantity: qty,
          method: "assign",
          custom: this.$store.getters.activeUser.is_client || undefined
        })
        .then(() => {
          //
        })
        .catch(() => {
          this.$store.dispatch("displayMessage", {
            type: "is-warning",
            message: this.$store.getters.generalErrorMessage
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeFromCart(product_id) {
      this.loading = true;
      this.$store
        .dispatch("addToCart", {
          cart_id: this.$store.getters.activeUser.cart.id,
          product_id: product_id,
          delete: true,
          method: "remove",
          custom: this.$store.getters.activeUser.is_client
        })
        .then(res => {
          if (this.$store.getters.activeUser.is_client)
            this.localData.splice(
              _.findIndex(this.localData, ["id", product_id])
            );
          else this.localData = res.data.cart.products;
        })
        .catch(() => {
          this.$store.dispatch("displayMessage", {
            type: "is-warning",
            message: this.$store.getters.generalErrorMessage
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendOrder() {
      this.loading = true;
      // let data = this.$store.getters.activeUser.is_client
      //   ? this.localData
      //   : this.data;
      let data = this.localData;
      this.$store
        .dispatch("sendOrder", {
          products: _.map(data, product => {
            return {
              id: product.id,
              quantity: product.pivot.quantity,
              price: product.pivot.price
            };
          }),
          sender: this.$store.getters.activeUser.is_client
            ? {
                name: this.$store.getters.clientCart.client_name,
                email: this.$store.getters.clientCart.client_email
              }
            : undefined
        })
        .then(res => {
          this.$router.push({ name: "Home" }, () => {
            this.$store.dispatch("displayMessage", {
              type: "is-success",
              message: res.data.message
            });
            if (this.$store.getters.activeUser.is_client)
              this.$store.commit("emptyClientCart");
          });
        })
        .catch(() => {
          this.$store.dispatch("displayMessage", {
            type: "is-warning",
            message: this.$store.getters.generalErrorMessage
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (
      (store.getters.userHasPermission("write-carts") ||
        store.getters.userHasPermission("write-custom-carts")) &&
      store.getters.userRole != "super-admin"
    ) {
      if (store.getters.activeUser.is_client) {
        let cart = JSON.parse(localStorage.getItem("cart"));
        if (_.isNull(cart)) {
          next({ name: "Continue As" });
          return;
        }
        store
          .dispatch("convertClientCart", {
            products: cart.products
          })
          .then(convertedCart => {
            next(vm => {
              vm.localData = convertedCart;
            });
          });
      } else {
        next(vm => {
          vm.localData = store.getters.userCart
            ? _.map(store.getters.userCart.products, p => {
                p.pivot.quantity = parseFloat(p.pivot.quantity);
                return p;
              })
            : [];
        });
      }
    } else {
      next({ name: "Home" });
    }
  }
};
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
}

.my-cart {
  min-height: 100vh;
  .is-empty {
    min-height: calc(100vh - 12rem);
  }
}
</style>
